import {MediaItem} from './media-item.interface';
import {GlossaryTerm} from "./glossary-term.interface";
import {LessonPathChapter} from "./lesson-path.chapter";
import {ChapterLesson} from "./chapter-lesson.interface";
import {MicromateTagList} from "./lesson-category.interface";

export interface Lesson {
  /**
   * ID
   */
  id: number;
  /**
   * Kursname
   */
  label: string;
  /**
   * Einstellungen
   */
  setting: any;

  isActive: string;
  /**
   * Empfohlener Kurs?
   */
  isrecommended?: boolean;

  isRecommendedFormatted: string;
  micromateTags: MicromateTagList[];
  /*
   * Pflichtkurs?
   */
  ismandatory?: boolean;

  /*
   * Zugriffsart
   */
  classification: string;

  /**
   * Bilder
   */
  mediaitems?: MediaItem[];

  /**
   * CommingSoon Kurs?
   */
  toCome?: boolean;
  /**
   * Veräffentlichungsdatum
   */
  published?: string;

  /**
   * Daten für Zertifikat. Bitte Sebastian für Details fragen
   */
  certificatepayload: {
    details: any[];
    description: string;
    sponsorlabel: string;
    sponsorsubline: string;
  };

  author_id: number;
  provider_id: number;

  tag_list: string;

  teasertext: string;
  price: number;

  teaser: { id: number; uri: string; parent_id: number; parenttype: number; position: string; };
  player: { id: number; uri: string; parent_id: number; parenttype: number; position: string; };
  language: string;

  glossaryterms: GlossaryTerm[];

  /**
   * _joinData is a cakephp special field, which is used to save additional data on associated relations.
   * In this case it is a table called chapters_lessons.
   *
   */
  _joinData?: ChapterLesson;

  chapters?: LessonPathChapter[]

  type?: string;

  /* HTML-Datei, die gestartet werden soll */
  startpage: string;
}
