import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LCMSConstants } from '../constants/lcms.constants';
import { Organisation } from '../models/organisation.interface';
import { BaseService } from './base-service';
import { RESTService } from './rest/rest.service';
import { HttpClient } from '@angular/common/http';
import {HttpGroupResponse, HttpSingleResponse} from "./user.service";
import { User } from '../models/user.interface';
import { Company } from '../../_root-store/company-store/company.state';
import {AddCodesDto} from "../models/add-codes.dto";
import {environment} from "../../../environments/environment";
import{GetMicromateResponse} from '../models/get.micromate.interface'
import{SaveMicromateResponse} from '../models/save-micromate.interface'

@UntilDestroy ( { checkProperties: true } )
@Injectable ( {
    providedIn: 'root',
} )
export class CompanyService implements BaseService{

    /**
     * Alle verfügbaren Firmen
     */
    readonly companies: BehaviorSubject<Organisation[]> = new BehaviorSubject<Organisation[]> ( [] );

    /**
     * Aktuell ausgewählte Firma
     */
    readonly company: BehaviorSubject<Organisation | undefined> = new BehaviorSubject<Organisation | undefined> ( undefined );

    constructor(
        private rest: RESTService,
        private http: HttpClient
    ) {
    }

    /**
     * Alle Firmen abrufen
     */
    fetchCompanies(): Subject<Organisation[]> {
        return this.rest.addRequest ( {
            method  : RESTService.GET,
            endpoint: LCMSConstants.COMPANIES,
        } )
                   .pipe (
                       tap ( companies => this.companies.next ( companies ) ),
                   ) as Subject<Organisation[]>;
    }

    /**
     * Lade aktive User
     */
    fetchCompaniesState(filter?: any): Observable<HttpGroupResponse<Company>> {
      let endpoint = LCMSConstants.COMPANIES;

      if (filter) {
        endpoint += filter;
      }

      return this.http.get<HttpGroupResponse<Company>>(endpoint);
    }

    /**
     * Firma aktivieren/deaktivieren
     * @param state
     * @param company
     */
    setCompanyActive( state: boolean, company: any ): void {
        company.setting.isactive = state;
        if ( company.hasOwnProperty ( 'isActive' ) ) {
            company.isActive = state ? 'aktiv' : 'gesperrt';
        }

        this.rest.addRequest ( {
            method  : RESTService.PUT,
            endpoint: LCMSConstants.COMPANIES + '/' + company.id,
            payload : company,
        } )
            .pipe (
            )
            .subscribe ( ( response ) => {
                if ( this.company?.value?.id === company.id ) {
                    this.loadDetails ( company.id )
                        .subscribe ( () => {
                        } );
                }
            } );
    }

    /**
     * Firma aktivieren/deaktivieren
     * @param company
     */
    setCompanyActiveState( company: Company ) {
        const tmpCompany = {...company, setting: {...company.setting, isactive: !company.setting.isactive}};

        return this.http.put(LCMSConstants.COMPANIES + '/' + company.id, tmpCompany);
    }

    /**
     * Details für eine Firma anhand der ID laden
     * @param id
     */
    loadDetails( id: string | number ): Subject<Organisation> {
        return this.rest.addRequest ( {
            method  : RESTService.GET,
            endpoint: LCMSConstants.COMPANIES + '/' + id,
        } )
                   .pipe (
                       tap ( company => this.company.next ( company ) ),
                   ) as Subject<Organisation>;
    }

    /**
     * Firma speichern
     * @param organisation
     */
    saveDetails( organisation: Organisation ): Subject<Organisation> {
        return this.rest.addRequest ( {
            method  : RESTService.PUT,
            endpoint: LCMSConstants.COMPANIES + '/' + organisation.id,
            payload : organisation,
        } );
    }

    /**
     * Firma löschen
     * @param organisation
     */
    deleteCompany( organisation: Organisation ): Subject<any> {
        return this.rest.addRequest ( {
            method  : RESTService.DELETE,
            endpoint: LCMSConstants.COMPANIES + '/' + organisation.id,
        } );
    }

    /**
     * Firma anlegen. Hier reicht ein Name, der Webservice erzeugt den Rest automatisch
     * @param label
     */
    addCompany( label: string ): Subject<any> {
        return this.rest.addRequest ( {
            method  : RESTService.POST,
            endpoint: LCMSConstants.COMPANIES,
            payload : {
                label,
                setting: {
                    isactive: false,
                },
            },
        } );
    }

    addEntity( entity: any ) {
        return this.addCompany(entity.textinput);
    }

    deleteEntity( entity: any ) {
        return this.deleteCompany(entity);
    }

    addCodes(amount: number, payload: AddCodesDto) {
      return this.http.post<HttpGroupResponse<[]>>(LCMSConstants.CODES + '?amount=' + amount, payload);
    }

    loadCodes(companyID: string | number | undefined, searchRequest: string) {
      return this.http.get<HttpGroupResponse<any>>(LCMSConstants.CODES + '?organisation_id=' + companyID + searchRequest);
    }

    exportCodes(companyID: string | number | undefined) {
      return this.http.get(LCMSConstants.CODES + '/export' + '?organisation_id=' + companyID, { responseType: "blob" });
    }

    saveMicromateId(companyID: number, micromateId: string, privateKey: string) {
      const url = `${LCMSConstants.ADD_MICROMATE_ID}/${companyID}`;
      return this.http.post<SaveMicromateResponse>(url, {micromateId, privateKey});
    }
    getMicromateId(companyID: number) {
    const url = `${LCMSConstants.GET_MICROMATE_ID}/${companyID}`;
    return this.http.get<GetMicromateResponse>(url);
  }
}
